import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div style={{ backgroundColor: 'rgb(248, 249, 251)', fontSize: '14px', lineHeight: '17px' }}>
        <style>
          {`
          .footer-container,
          .footer-bottom-bar {
            width: 92.3%;
            max-width: 1070px;
            margin: auto;
          }

          .footer-container {
            box-sizing: content-box;
            position: relative;
            padding: 30px 0 30px;
            min-height: 195px;
          }

          .footer-container .footer-column {
            position: relative;
            box-sizing: border-box;
            float: left;
            width: 25%;
            padding-inline-end: 6.25%;
          }

          [dir="rtl"] .footer-container .footer-column {
            float: right;
          }

          .footer-container .footer-column .footer-column-header {
            box-sizing: content-box;
            color: rgba(0,0,0,.87);
            line-height: 23px;
            font-weight: 500;
          }

          .footer-container .footer-column .footer-column-expand-caret {
            display: none;
          }

          .footer-container .footer-column a {
            display: table;
            color: rgba(0,0,0,.87);
            text-decoration: none;
            line-height: 17px;
            margin-top: 6px;
          }
          .footer-container .footer-column a:hover {
            color: black;
          }

          .footer-container .langue-selection-block {
            position: absolute;
            box-sizing: border-box;
            padding-block-start: 0;
            padding-block-end: 15px;
            padding-inline-start: 0;
            padding-inline-end: 6.25%;
            bottom: 15px;
            width: 25%;
          }
          .footer-container .langue-selection-block .langue-header {
            color: rgba(0,0,0,.87);
            font-weight: 500;
          }

          .footer-bottom-bar a {
            line-height: 56px;
            margin-inline-end: 40px;
            color: white;
            text-decoration: none;
          }

          @media (max-width: 767px) {
            .footer-container,
            .footer-bottom-bar {
              width: 100%;
              padding: 0;
              max-width: none;
            }

            .footer-container .footer-column {
              padding: 0;
              width: 100%;
            }

            .footer-container .footer-column a,
            .footer-container .footer-column .footer-column-header {
              margin-top: 0;
              padding: 10px 40px;
              width: calc(100% - 80px);
              line-height: 25px;
            }

            .footer-container .footer-column .footer-column-header,
            .footer-container .langue-selection-block {
              border-top: solid 1px #D4D4D4;
            }

            .footer-container .footer-column .footer-column-header {
              cursor: pointer;
              background-color: #F8F9FB;
            }

            .footer-container .footer-column .footer-column-expand-caret {
              display: block;
              position: absolute;
              inset-inline-start: 16px;
              top: 15px;
              width: 15px;
              height: 20px;
              transform-origin: 55% 45%;
              cursor: pointer;
            }
            .footer-container .footer-column.expanded .footer-column-expand-caret {
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
            }

            .footer-container .footer-column a {
              font-weight: 600;
              color: rgba(0,0,0,.87);
              border-top: solid 1px #E5E5E5;
              display: none;
            }
            .footer-container .footer-column.expanded a {
              display: block;
              box-sizing: content-box;
            }

            .footer-container .langue-selection-block {
              position: static;
              width: 100%;
              height: inherit;
              top: inherit;
              padding: 14px 20px 15px;
            }

            .footer-bottom-bar a {
              display: block;
              line-height: 45px;
              margin-right: 0;
              padding-inline-start: 20px;
              border-bottom: solid 1px #D4D4D4;
            }
          }
          `}
        </style>
        <div className="footer-container" id="footerContainer">
          <div className="footer-column">
            <div className="footer-column-expand-caret">
              <svg width="15" height="20" fill="rgba(0,0,0,.87)">
                <g>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="-2"
                    x="5"
                    strokeWidth="1"
                  ></rect>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(-45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="13.5"
                    x="-8"
                    strokeWidth="1"
                  ></rect>
                </g>
              </svg>
            </div>
            <div className="footer-column-header">Company</div>
            <div>
              <a href="https://snap.com/en-US">Snap Inc.</a>
              <a href="https://careers.snap.com">Careers</a>
              <a href="https://newsroom.snap.com">News</a>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-expand-caret">
              <svg width="15" height="20" fill="rgba(0,0,0,.87)">
                <g>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="-2"
                    x="5"
                    strokeWidth="1"
                  ></rect>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(-45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="13.5"
                    x="-8"
                    strokeWidth="1"
                  ></rect>
                </g>
              </svg>
            </div>
            <div className="footer-column-header">Community</div>
            <div>
              <a href="https://help.snapchat.com/hc/?utm_source=web&amp;utm_medium=snap&amp;utm_campaign=ap">Support</a>
              <a href="https://snap.com/en-US/community-guidelines">Community Guidelines</a>
              <a href="https://snap.com/en-US/safety/safety-center">Safety Center</a>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-expand-caret">
              <svg width="15" height="20" fill="rgba(0,0,0,.87)">
                <g>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="-2"
                    x="5"
                    strokeWidth="1"
                  ></rect>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(-45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="13.5"
                    x="-8"
                    strokeWidth="1"
                  ></rect>
                </g>
              </svg>
            </div>
            <div className="footer-column-header">Advertising</div>
            <div>
              <a href="https://forbusiness.snapchat.com/?utm_source=accounts_snapchat_com&amp;utm_medium=referral&amp;utm_campaign=internal_referral&amp;utm_content=footer_link">Buy Ads</a>
              <a href="https://snap.com/en-US/ad-policies/">Advertising Policies</a>
              <a href="https://snap.com/en-US/political-ads/">Political Ads Library</a>
              <a href="https://snap.com/en-US/brand-guidelines/">Brand Guidelines</a>
              <a href="https://help.snapchat.com/hc/articles/7047502545044?utm_source=web&amp;utm_medium=snap&amp;utm_campaign=ap">Promotions Rules</a>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-expand-caret">
              <svg width="15" height="20" fill="rgba(0,0,0,.87)">
                <g>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="-2"
                    x="5"
                    strokeWidth="1"
                  ></rect>
                  <rect
                    stroke="rgba(0,0,0,.87)"
                    transform="rotate(-45 0,0)"
                    rx="1"
                    height="1.5"
                    width="10"
                    y="13.5"
                    x="-8"
                    strokeWidth="1"
                  ></rect>
                </g>
              </svg>
            </div>
            <div className="footer-column-header">Legal</div>
            <div>
              <a href="https://snap.com/en-US/privacy/privacy-center/">Privacy Center</a>
              <a href="https://help.snapchat.com/hc/articles/11399265637012?utm_source=snapchat_com&amp;utm_medium=global_footer&amp;utm_campaign=privacy_choices">Your Privacy Choices</a>
              <a href="https://snap.com/en-US/cookie-policy/">Cookie Policy</a>
              <a href="https://help.snapchat.com/hc/articles/7012332110996?utm_source=web&amp;utm_medium=snap&amp;utm_campaign=ap">Report Infringement</a>
              <a href="https://snap.com/en-US/terms/custom-creative-tools/">Custom Creative Tools Terms</a>
              <a href="https://snapchat.com/create/terms.html">Community Geofilter Terms</a>
              <a href="https://snap.com/en-US/terms/lens-studio-license-agreement">Lens Studio Terms</a>
            </div>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div className="langue-selection-block">
            <div className="langue-header">Language</div>
            <div style={{ position: 'relative' }}>
              <select
                id="sc-global-locale-selector"
                style={{
                  appearance: 'none',
                  border: '1px solid rgb(204, 204, 204)',
                  borderRadius: '5px',
                  background: 'white',
                  width: '100%',
                  marginTop: '10px',
                  padding: '5px 10px',
                  fontSize: '14px',
                  fontFamily: 'inherit',
                  color: 'rgba(0, 0, 0, 0.6)',
                  height: '34px',
                  cursor: 'pointer',
                }}
              >
                <option value="en-US">English (US)</option>
                <option value="ar">العَرَبِية</option>
                <option value="bn-BD">বাংলা(বাংলাদেশ)</option>
                <option value="bn-IN">বাংলা (ভারত)</option>
                <option value="da-DK">Dansk</option>
                <option value="de-DE">Deutsch</option>
                <option value="el-GR">Ελληνικά</option>
                <option value="en-GB">English (UK)</option>
                <option value="es">Español</option>
                <option value="es-AR">Español (Argentine)</option>
                <option value="es-ES">Español (España)</option>
                <option value="es-MX">Español (México)</option>
                <option value="fi-FI">Suomi</option>
                <option value="fil-PH">Filipino</option>
                <option value="fr-FR">Français</option>
                <option value="gu-IN">ગુજરાતી</option>
                <option value="hi-IN">हिंदी</option>
                <option value="id-ID">Bahasa Indonesia</option>
                <option value="it-IT">Italiano</option>
                <option value="ja-JP">日本語</option>
                <option value="kn-IN">ಕನ್ನಡ (India)</option>
                <option value="ko-KR">한국어</option>
                <option value="ml-IN">മലയാളം</option>
                <option value="mr-IN">मराठी</option>
                <option value="ms-MY">Bahasa Melayu</option>
                <option value="nb-NO">Norsk</option>
                <option value="nl-NL">Nederlands</option>
                <option value="pa">ਪੰਜਾਬੀ</option>
                <option value="pl-PL">Polski</option>
                <option value="pt-BR">Português (Brasil)</option>
                <option value="pt-PT">Português (Portugal)</option>
                <option value="ro-RO">Română</option>
                <option value="ru-RU">Русский</option>
                <option value="sv-SE">Svenska</option>
                <option value="ta-IN">தமிழ்</option>
                <option value="te-IN">తెలుగు</option>
                <option value="th-TH">ภาษาไทย (ประเทศไทย)</option>
                <option value="tr-TR">Türkçe</option>
                <option value="ur-PK">اردو</option>
                <option value="vi-VN">Tiếng Việt</option>
                <option value="zh-Hans">中文（简体）</option>
                <option value="zh-Hant">中文（繁體）</option>
              </select>
              <div
                style={{
                  position: 'absolute',
                  insetInlineEnd: '10px',
                  width: '0px',
                  height: '0px',
                  pointerEvents: 'none',
                  borderStyle: 'solid',
                  bottom: '18px',
                  borderWidth: '0px 3px 5px',
                  borderColor: 'transparent transparent rgb(102, 102, 102)',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  insetInlineEnd: '10px',
                  width: '0px',
                  height: '0px',
                  pointerEvents: 'none',
                  borderStyle: 'solid',
                  bottom: '10px',
                  borderWidth: '5px 3px 0px',
                  borderColor: 'rgb(102, 102, 102) transparent transparent',
                }}
              ></div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: 'rgb(38, 38, 38)' }}>
          <div className="footer-bottom-bar">
            <a href="https://snap.com/en-US/privacy/privacy-policy/">Privacy Policy</a>
            <a href="https://snap.com/en-US/terms/">Terms of Service</a>
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
