import Footer from "./Footer";
import LoginForm from "./LoginForm";

function Body() {
  return (
    <div className='flex flex-col justify-between bg-background-gray h-screen'>
        <LoginForm />
        <Footer />
    </div>
  );
}

export default Body;
